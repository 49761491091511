<script>
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";
import { VclList } from "vue-content-loading";
import Stat from "@/components/widgets/stat";

export default {
  locales: {
    pt: {
      Team: "Equipe",
      Username: "Usuário",
      Name: "Nome",
      Date: "Data",
      Level: "Nível",
      Plano: "Plano",
      Status: "Status",
      Qualified: "Qualificado",
      Unqualified: "Não Qualificado",
      Left: "Esquerdo",
      Right: "Direito",
      Pending: "Pendente",
      Approved: "Aprovado",
      "We apologize, we are unable to retrieve information at this time. Please try again later.":
        "Pedimos desculpas, não podemos recuperar informações no momento. Por favor, tente novamente mais tarde.",
      "No records found.": "Nenhum registro encontrado.",
      Graduation: "Graduação",
    },
    es: {
      Team: "Equipe",
      Username: "Nombre de Usuario",
      Name: "Nombre",
      Date: "Data",
      Level: "Nivel",
      Plano: "Plano",
      Status: "Status",
      Qualified: "Calificado",
      Unqualified: "No Cualificado",
      Left: "Izquierdo",
      Right: "Derecho",
      Pending: "Pendiente",
      Approved: "Aprobado",
      "We apologize, we are unable to retrieve information at this time. Please try again later.":
        "Nos disculpamos, no podemos recuperar información en este momento. Por favor, inténtelo de nuevo más tarde.",
      "No records found.": "No se encontraron registros.",
      Graduation: "Graduación",
    },
  },
  components: {
    Layout,
    VclList,
    Stat,
  },
  data() {
    return {
      page: 1,
      pages: 0,
      table: {
        heade: [
          "Name",
          "Date",
          "Level",
          "Plano",
          "Status",
        ],
        body: null,
        loading: true,
        errored: false,
        empty: false,
      },

      statData: null,
    };
  },
  methods: {
    getTeam() {
      this.table.loading = true;
      api
        .get("network/team?page=" + this.page)
        .then((response) => {
          if (response.data.status == "success") {
            this.table.body = response.data.list;
            this.statData = [
              {
                icon: "bx bx-user",
                title: "Total",
                value: response.data.total,
              },
            ];
            this.pages = response.data.pages;
          }
        })
        .catch((error) => {
          this.table.errored = error;
        })
        .finally(() => {
          this.table.loading = false;
          if (this.table.body == "" || this.table.body == null) {
            this.table.empty = true;
          }
        });
    },
  },
  mounted() {
    this.getTeam();
  },
  watch: {
    page: function () {
      this.getTeam();
    },
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0 font-size-18">{{ t("Team") }}</h4>
        </div>
      </div>
    </div>

    <div class="row">
      <div v-for="(stat, index) of statData" :key="index" class="col-md-4">
        <Stat :icon="stat.icon" :title="stat.title" :value="stat.value" />
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div v-if="table.errored">
              {{
                t(
                  "We apologize, we are unable to retrieve information at this time. Please try again later."
                )
              }}
            </div>
            <div v-else-if="table.empty">{{ t("No records found.") }}</div>
            <div v-else class="table-responsive">
              <vcl-list v-if="table.loading" class="col-lg-6"></vcl-list>
              <table v-else class="table table-centered table-nowrap">
                <thead class="thead-light">
                  <tr>
                    <th v-for="th in table.heade" :key="th">{{ t(th) }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(td, index) in table.body" :key="index">
                    <td v-for="(data, row) in td" :key="row">
                      <div v-if="row == 'package'">
                        <span class="badge badge-soft-dark font-size-12">{{
                          data
                        }}</span>
                      </div>
                      <div v-else-if="row === 'monthly'">
                        <span
                          v-if="data === 'inactive'"
                          class="badge badge-soft-danger font-size-12"
                          >INATIVO</span
                        >
                        <span
                          v-else-if="data === 'active'"
                          class="badge badge-soft-success font-size-12"
                          >ATIVO</span
                        >
                      </div>
                      <div v-else-if="row == 'graduation'">
                        <span class="badge font-size-12">{{ data.name }}</span>
                      </div>
                      <div v-else-if="row === 'status'">
                        <span
                          v-if="data === 'Pending'"
                          class="badge badge-soft-warning font-size-12"
                          >{{ t(data) }}</span
                        >
                        <span
                          v-else-if="data === 'Approved'"
                          class="badge badge-soft-success font-size-12"
                          >{{ t(data) }}</span
                        >
                      </div>
                      <div v-else class="notranslate">
                        {{ data }}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <paginate
              v-if="!table.errored && !table.empty"
              v-model="page"
              :page-count="pages"
              :page-range="3"
              :margin-pages="2"
              prev-text="<i class='mdi mdi-chevron-left'></i>"
              next-text="<i class='mdi mdi-chevron-right'></i>"
              :container-class="'pagination pagination-rounded justify-content-center mt-4'"
              :page-class="'page-item'"
              :page-link-class="'page-link'"
              :prev-link-class="'page-link'"
              :next-link-class="'page-link'"
              :break-view-link-class="'page-link'"
            >
            </paginate>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
